import React,  { Component } from 'react';
import { List } from '@mui/material';
import Tab from './Tab';

const TabManager = ({ tabs, currentTabUuid, selectTab, deleteTab, renameTab, shareTab }) => (
    <List>
        {tabs.map((tab) => (
            <Tab
                key={tab.uuid}
                {...tab}
                isActive={tab.uuid === currentTabUuid}
                selectTab={selectTab}
                deleteTab={deleteTab}
                renameTab={renameTab}
                shareTab={shareTab}
            />
        ))}
    </List>
);

export default TabManager;