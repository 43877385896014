import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Redirect } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import HomePage from './HomePage';
import LoginPage from './LoginPage'
import { createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import PrivateRoute from './PrivateRoute';

export const appTheme = createTheme({
    palette: {
        primary: {
            main: orange[800],
        },
    },
});

export default class App extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Router>
                <Routes>
                    <Route path="/" element={<PrivateRoute><HomePage /></PrivateRoute>} />
                    <Route path='/login' element={<LoginPage />} />
                </Routes>
            </Router>
        );
    }
}

const appDiv = document.getElementById("app");
const root = createRoot(appDiv);
root.render(<App />);