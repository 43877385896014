import React,  { Component, useState } from 'react';
import { ListItem, Button, TextField, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import IosShareIcon from '@mui/icons-material/IosShare';

const Tab = ({ uuid, name, isActive, selectTab, deleteTab, shareTab, renameTab }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(name);

    const handleRename = () => {
        renameTab(uuid, newName);
        setIsEditing(false);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleRename();
        }
    }

    const activeStyle = {
        backgroundColor: '#e0e0e0',
    };

    return (
        <ListItem style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {isEditing ? (
                <TextField
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    onBlur={handleRename}
                    autoFocus
                    onKeyDown={handleKeyPress}
                    style={{ flex: 1 }}
                />
            ) : (
                <Button
                    onClick={() => selectTab(uuid)}
                    onDoubleClick={() => setIsEditing(true)}
                    style={{
                        ...{ flex: 1, display: 'inline-flex', overflow: 'hidden' },
                        ...(isActive ? activeStyle : {})
                    }}
                >
                    <span 
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '150px',
                        }}
                    >
                        {name}
                    </span>
                </Button>
            )}

            <IconButton edge='end' aria-label='delete' onClick={() => deleteTab(uuid)}>
                <DeleteIcon />
            </IconButton>

            <IconButton edge='end' aria-label='share' onClick={() => shareTab(uuid)} style={{ 'marginLeft': '25px'}}>
                <IosShareIcon />
            </IconButton>
        </ListItem>
    );
};

export default Tab;