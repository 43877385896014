import React, { useState, useEffect } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { getCookie } from './Functions';

const PrivateRoute = ({ children }) => {
    const [isLoading, setLoading] = useState(true);
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [publicTab, setPublicTab] = useState(null);

    const location = useLocation();

    const getTabUUIDFromURL = () => {
        const params = new URLSearchParams(location.search);
        return params.get('tab');
    };

    useEffect(() => {
        const fetchData = async () => {
            const tabUUID = getTabUUIDFromURL();
            let publicTabPromise = null;

            if (tabUUID) {
                publicTabPromise = fetch(`/api/public-tab/${tabUUID}/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken'),
                    },
                    credentials: 'include',
                }).then(response => response.json());
            }

            const userPromise = fetch('/api/auth/user/?format=json', {
                method: 'GET',
            }).then(response => response.json());

            const results = await Promise.all([publicTabPromise, userPromise]);

            if (publicTabPromise) {
                setPublicTab(results[0]);
            }

            const userData = results[1];
            if (userData.user && userData.user.username && userData.user.id) {
                setAuthenticated(true);
            }

            setLoading(false);
        };

        fetchData().catch(error => {
            console.error('Error:', error);
            setLoading(false);
        });

    }, [location.search]);

    if (isLoading) {
        return <div><CircularProgress /></div>;
    }

    if (publicTab) {
        if (isAuthenticated) {
            return React.cloneElement(children, { publicTab });
        } else {
            return <Navigate to="/login" replace={true} />;
        }
    }    

    return isAuthenticated ? children : <Navigate to="/login" replace={true} />;
};

export default PrivateRoute;