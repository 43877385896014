import React, { useState } from 'react';
import { FormControlLabel, Checkbox, TextField, Container, Button, Box } from '@mui/material';
import BinaryToggle from './BinaryToggle';
import { appTheme } from './App';
import { ThemeProvider } from '@mui/material';
import { MuiChipsInput } from 'mui-chips-input';
import { getCookie } from './Functions';

const SearchForm = ({ search, submitTab }) => {
    const [toggleState, setToggleState] = useState('people');
    const [query, setQuery] = useState({
        companies: [],
        education: [],
        positions: [],
        locations: [],
        companyTypes: [],
        industries: []
    });
    const [isRecurring, setIsRecurring] = useState(false);
    const [endDate, setEndDate] = useState('');

    const handleChange = (newChips, category) => {
        setQuery({
            ...query,
            [category]: newChips
        });
    };

    const handleRecurringChange = (event) => {
        setIsRecurring(event.target.checked);
    };
    
    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (Object.values(query).every((value) => value.length === 0)) {
            return;
        }
        
        let searchQuery;
        
        if (toggleState === 'people') {
            searchQuery = {
                searchType: 'P',
                searchDepth: 'T',
                query: query
            };
        } else if (toggleState === 'company') {
            searchQuery = {
                searchType: 'C',
                searchDepth: 'T',
                query: query
            };
        }
        console.log(JSON.stringify(searchQuery, null, 2));

        search(searchQuery).then((data) => {
            console.log(data);
            submitTab();
            if (isRecurring && endDate) {
                const csrfToken = getCookie('csrftoken');
                fetch('/api/create-recurring-search/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken'),
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        end_date: endDate,
                        query: searchQuery.query,
                        searchType: searchQuery.searchType
                    })
                })
                .then(response => response.json())
                .then(data => {
                    console.log("RecurringSearch created:", data);
                })
                .catch(error => {
                    console.error("Error creating RecurringSearch:", error);
                });
            }
        });
    };

    return (
        <ThemeProvider theme={appTheme}>
            <Container maxWidth="md">
                <form onSubmit={handleSubmit}>
                    <BinaryToggle toggleState={toggleState} setToggleState={setToggleState} />
                    {toggleState === 'people' ? (
                        <>
                            <Box width="400px" my={2}><MuiChipsInput fullWidth value={query.companies} onChange={(chips) => handleChange(chips, 'companies')} label="Companies" /></Box>
                            <Box width="400px" my={2}><MuiChipsInput fullWidth value={query.education} onChange={(chips) => handleChange(chips, 'education')} label="Education" /></Box>
                            <Box width="400px" my={2}><MuiChipsInput fullWidth value={query.industries} onChange={(chips) => handleChange(chips, 'industries')} label="Industries" /></Box>
                            <Box width="400px" my={2}><MuiChipsInput fullWidth value={query.positions} onChange={(chips) => handleChange(chips, 'positions')} label="Positions" /></Box>
                            <Box width="400px" my={2}><MuiChipsInput fullWidth value={query.locations} onChange={(chips) => handleChange(chips, 'locations')} label="Locations" /></Box>
                        </>
                    ) : (
                        <>
                            <Box width="400px" my={2}><MuiChipsInput fullWidth value={query.companyTypes} onChange={(chips) => handleChange(chips, 'companyTypes')} label="Company Types" /></Box>
                            <Box width="400px" my={2}><MuiChipsInput fullWidth value={query.industries} onChange={(chips) => handleChange(chips, 'industries')} label="Industries" /></Box>
                            <Box width="400px" my={2}><MuiChipsInput fullWidth value={query.locations} onChange={(chips) => handleChange(chips, 'locations')} label="Locations" /></Box>
                        </>
                    )}
                    <Box my={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isRecurring}
                                    onChange={handleRecurringChange}
                                    color="primary"
                                />
                            }
                            label="Enable Recurring Search"
                        />
                    </Box>

                    {isRecurring && (
                        <Box my={2}>
                            <TextField
                                label="End Date for Recurring Search"
                                type="date"
                                value={endDate}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    min: new Date().toISOString().split('T')[0],
                                }}
                                variant="outlined"
                                fullWidth
                                onChange={handleEndDateChange}
                            />
                        </Box>
                    )}
                    <Box my={2} display="flex" justifyContent="center">
                        <Button type="submit" variant="contained" color="primary">Search</Button>
                    </Box>
                </form>
            </Container>
        </ThemeProvider>
    );
};

export default SearchForm;