import React,  { Component, useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Button, Typography, Paper, DividerTypeMap, CircularProgress, Divider } from '@mui/material';
import { getCookie } from './Functions';
// import { fetchDomain } from './searchApi';

const SearchResults = ({ uuid, results, type, selectTab, updateUserTabs }) => {
    let columns, data;

    console.log("updateUserTabs is:", updateUserTabs);
    console.log("Type of updateUserTabs is:", typeof updateUserTabs);

    // const [selectedRows, setSelectedRows] = React.useState([]);
    const selectedRowsRef = React.useRef([]);
    const [searchQuery, setSearchQuery] = useState({});
    const [searchType, setSearchType] = useState("");
    const [searchDepth, setSearchDepth] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    function getDepthColumns(linkType, detailsComponentName) {
        const formattedLinkName = capitalizeFirstLetter(linkType);
        return [
            {
                name: linkType,
                label: formattedLinkName,
                options: {
                    display: false,
                    viewColumns: true,
                    filter: false,
                    customBodyRender: (value) => {
                        return value.startsWith('http://') || value.startsWith('https://') 
                            ? value 
                            : 'https://' + value;
                    }
                }
            },
            // { name: "name", label: "Name", options: { display: true, viewColumns: true } },
            // { name: "description", label: "Description", options: { display: true, viewColumns: true } },
            // { name: "location", label: "Location", options: { display: true, viewColumns: true } },
            {
                name: "data",
                label: "Details",
                options: {
                    customBodyRender: (value) => {
                        if (detailsComponentName === "Person") {
                            return <PersonDetailsCard {...value} />;
                        } else {
                            return <CompanyDetailsCard {...value} />;
                        }
                    },
                    sort: false,
                    download: false,
                    filter: false,
                    searchable: false,
                    viewColumns: false
                }
            }
        ];
    }
    
    function transformDepthData(results, linkType) {
        return Object.entries(results).map(([link, details]) => ({
            [linkType]: link,
            // name: details.name,
            // description: details.description,
            // location: details.location,
            data: {
                [linkType]: link,
                ...details
            }
        }));
    }
    
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).replace('Link', ' Link');
    }
    
    switch(type) {
        case 'People Top':
        case 'Company Top':
            columns = [
                "Title", 
                {
                    name: "Link",
                    options: {
                        customBodyRender: (value) => {
                            return (
                                <a href={value} target="_blank" rel="noopener noreferrer">
                                    {value}
                                </a>
                            );
                        }
                    }
                }, 
                "Snippet"
            ];
            if (results.hasOwnProperty('search_results')) {
                data = Object.entries(results.search_results.results).map(([url, value]) => [value.title, url, value.snippet]);
            } else if (results.hasOwnProperty('results')) {
                data = Object.entries(results.results).map(([url, value]) => [value.title, url, value.snippet]);
            } else {
                data = Object.entries(results).map(([url, value]) => [value.title, url, value.snippet]);
            }
            break;
    
        case 'People Depth':
            columns = getDepthColumns("profileLink", "Person");
            if (results.hasOwnProperty('results')) {
                data = transformDepthData(results.results, "profileLink");
            } else {
                data = transformDepthData(results, "profileLink");
            }
            break;
    
        case 'Company Depth':
            columns = getDepthColumns("companyLink", "Company");
            if (results.hasOwnProperty('results')) {
                data = transformDepthData(results.results, "companyLink");
            } else {
                data = transformDepthData(results, "companyLink");
            }
            break;
    
        default:
            columns = [];
            data = [];
    }

    const PersonDetailsCard = ({ profileLink, name, description, location, education, experience }) => {
        const formattedProfileLink = profileLink.startsWith('http://') || profileLink.startsWith('https://') 
                                     ? profileLink : 'https://' + profileLink;

        const [email, setEmail] = useState(null);

        const fetchDomain = async (companyName) => {
            const apiKey = 'AIzaSyDI-vuniAnKCeeagKKw4d47_rh_YBt-oqc';
            const searchEngineId = 'a0e96f7ce67974a1c';
            const url = `https://www.googleapis.com/customsearch/v1?q=${companyName}&key=${apiKey}&cx=${searchEngineId}`;
    
            try {
                const response = await fetch(url);
                const data = await response.json();
                if (data.items && data.items[0] && data.items[0].link) {
                    const domain = new URL(data.items[0].link).hostname;
                    return domain;
                }
            } catch (error) {
                console.error("Error fetching domain:", error);
            }
            return null;
        };
    
        const fetchEmail = async (company) => {
            const domain = await fetchDomain(company);
            if (domain) {
                let [firstName, lastName] = name.split(" ");

                firstName = firstName.replace(/[^a-zA-Z]/g, '');
                lastName = lastName.replace(/[^a-zA-Z]/g, '');

                if (!firstName || !lastName || firstName.length === 1 || lastName.length === 1) {
                    console.error("Invalid first or last name.");
                    return;
                }

                const hunterURL = `https://api.hunter.io/v2/email-finder?domain=${domain}&first_name=${firstName}&last_name=${lastName}&api_key=c5ea05bc007b75fccf1ee1810e46d622488af0d6`;
                try {
                    const response = await fetch(hunterURL);
                    const data = await response.json();
                    if (data.data && data.data.email) {
                        setEmail(data.data.email);
                    }
                } catch (error) {
                    console.error("Error fetching email:", error);
                }
            }
        };
    
        const emailSubject = "Introduction";
        const emailBody = `Hello ${name.split(" ")[0]},\n\nI came across your profile and would like to connect with you. Looking forward to hearing from you.\n\nBest regards,\n[Your Name]`;
        const mailtoLink = email ? `mailto:${email}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}` : null;

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', padding: '10px', border: '1px solid #e0e0e0', borderRadius: '5px' }}>
                <a href={formattedProfileLink} target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#3f51b5' }}>{name}</a>
                <span style={{ marginTop: '5px', color: '#555' }}>{description}</span>
                <span style={{ marginTop: '5px', color: '#777' }}>{location}</span>

                {(experience && experience[0] && experience[0].company) && (
                    <Button 
                        variant="contained" 
                        color="primary" 
                        style={{ marginTop: '10px' }} 
                        onClick={() => fetchEmail(experience[0].company)}
                    >
                        Get Contact Email
                    </Button>
                )}
                
                {mailtoLink && (
                    <a href={mailtoLink} style={{ marginTop: '5px', textDecoration: 'none', color: '#3f51b5' }}>Contact via Email</a>
                )}
                
                <div style={{ marginTop: '10px' }}>
                    <Typography variant="h6">Education</Typography>
                    {(education || []).map((edu, index) => (
                        <div key={index} style={{ marginTop: '5px', marginLeft: '10px' }}>
                            <Typography variant="subtitle1">{edu.school}</Typography>
                            <Typography variant="body2">{edu.degree} in {edu.field}</Typography>
                            <Typography variant="caption">{edu.start_year} - {edu.end_year || 'Present'}</Typography>
                        </div>
                    ))}
                </div>
    
                <div style={{ marginTop: '10px' }}>
                    <Typography variant="h6">Experience</Typography>
                    {(experience || []).map((exp, index) => (
                        <div key={index} style={{ marginTop: '5px', marginLeft: '10px' }}>
                            <Typography variant="subtitle1">{exp.company}</Typography>
                            <Typography variant="body2">{exp.position}</Typography>
                            <Typography variant="caption">{exp.start_year} - {exp.end_year || 'Present'}</Typography>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const CompanyDetailsCard = (props) => {
        let website = props.website;
        if (props.website.startsWith('www.')) {
            website = 'https://' + props.website;
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', padding: '10px', border: '1px solid #e0e0e0', borderRadius: '5px' }}>
                <Typography variant="h6">{props.name}</Typography>
                <Typography variant="subtitle1">{props.description}</Typography>
                <Typography variant="body2">Industry: {props.industries.join(', ')}</Typography>
                <Typography variant="body2">Location: {props.location}</Typography>
                <Typography variant="body2">Founded: {props.founded}</Typography>
                <Typography variant="body2">Size: {props.company_size}</Typography>
                <Typography variant="body2">Funding Stage: {props.funding_stage}</Typography>
                <Typography variant="body2">Funding Date: {props.funding_date}</Typography>
                <Typography variant="body2">Funding Amount: {props.funding_amount}</Typography>
                <a href={website} target="_blank" rel="noopener noreferrer" style={{ marginTop: '5px', textDecoration: 'none', color: '#3f51b5' }}>{website}</a>
            </div>
        );
    } 

    const options = {
        selectableRows: 'multiple',
        onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
            const selectedURLs = allRowsSelected.map(row => data[row.dataIndex][1]);
            selectedRowsRef.current = selectedURLs;
            // console.log("Selected URLs:", selectedURLs);
        },
        onRowsDelete: (rowsDeleted) => {
            return false;
        },
        searchOpen: false,
        search: false,
        filter: false,
    };    

    const onRunDeepAnalysis = () => {
        // Extract URLs from selected rows
        const selectedUrls = selectedRowsRef.current.map(url => url);

        if (selectedUrls.length > 25) {
            alert(`You have selected ${selectedUrls.length} URLs. Please select 25 or less URLs to run deep analysis.`);
            return;
        }

        // Determine search type
        let searchType = '';
        if (type === "People Top") searchType = 'P';
        else if (type === "Company Top") searchType = 'C';

        // Execute deep analysis
        deepAnalysisFunction(selectedUrls, searchType, 'D');
    };

    const deepAnalysisFunction = (urls, searchType, depth) => {
        if (urls.length === 0) {
            return;
        }

        setIsLoading(true);
        
        let searchQuery;
        
        const commonQueryStructure = {
            searchType: searchType,
            searchDepth: depth,
            query: {
                analysis_urls: urls
            }
        };
    
        if (searchType === 'P') {
            searchQuery = {...commonQueryStructure};
        } else if (searchType === 'C') {
            searchQuery = {...commonQueryStructure};
        }
        console.log(JSON.stringify(searchQuery, null, 2));

        fetch('/api/create-tab/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            credentials: 'include',
            body: JSON.stringify(searchQuery),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            console.log("Calling updateUserTabs...");
            return updateUserTabs().then(() => data);
        })
        .then(data => {
            selectTab(data.tab.uuid);
        })
        .catch(error => {
            console.error(error);
            alert('There was an error with the deep analysis. Please try again later.');
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (!uuid) {
            console.error('UUID is undefined. Aborting fetch operation.');
            return;
        }

        fetch(`/api/tab/${uuid}/`)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setSearchType(data.searchType);
                setSearchDepth(data.searchDepth);
                setSearchQuery(data.query);
            })
            .catch(error => {
                console.error(error);
                fetch(`/api/public-tab/${uuid}/`)
                    .then(response => response.json())
                    .then(data => {
                        console.log(data);
                        setSearchType(data.tab.searchType);
                        setSearchDepth(data.tab.searchDepth);
                        setSearchQuery(data.tab.query);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            })
    }, [uuid]);

    return (
        <>
            {isLoading ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <CircularProgress />
                    <Typography variant="body2" style={{ marginTop: '20px' }}>
                        Analyzing URLs... This can take up to a minute and a half, especially with more URLs.
                    </Typography>
                </div>
            ) : (
                <>
                    <Paper elevation={2} style={{ padding: '15px', marginBottom: '15px' }}>
                        {/* Search Type */}
                        <Typography variant="h6">Search Type:</Typography>
                        <Typography>{searchType === "C" ? "Company" : "Person"}</Typography>
                        
                        {/* Search Depth */}
                        <Typography variant="h6" style={{marginTop: '10px'}}>Search Depth:</Typography>
                        <Typography>{searchDepth === "T" ? "Top Level" : "Deep Level"}</Typography>

                        {/* Query Terms */}
                        {searchDepth === "T" && (
                            <>
                                <Typography variant="h6" style={{marginTop: '10px'}}>Query Terms:</Typography>
                                {Object.entries(searchQuery).map(([key, values]) => {
                                    if (values && values.length > 0) {
                                        return (
                                            <div key={key}>
                                                <Typography variant="subtitle1">{capitalizeFirstLetter(key)}:</Typography>
                                                {values.map(value => (
                                                    <Typography key={value} variant="body2" style={{marginLeft: '10px'}}>- {value}</Typography>
                                                ))}
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </>
                        )}
                        <Divider style={{ margin: '10px 0' }} />
                    </Paper>
                    
                    <MUIDataTable
                        title={"Search Results"}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                    {(type === 'People Top' || type === 'Company Top') &&
                        <Button 
                            variant="contained" 
                            onClick={onRunDeepAnalysis}
                        >
                            Run Deep Analysis
                        </Button>
                    }
                </>
            )}
        </>
    );

};

export default SearchResults;