import React,  { Component, useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import TabManager from './TabManager';
import TabQuery from './TabQuery';
import TabResults from './TabResults';
import MenuIcon from '@mui/icons-material/Menu'
import { IconButton } from '@mui/material';
import { getCookie } from './Functions';

const HomePage = ({ publicTab }) => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [currentTabUuid, setCurrentTabUuid] = useState(null);
    const [tabs, setTabs] = useState([]);
    const [newTabInProgress, setNewTabInProgress] = useState(false);
    
    const getUserTabs = async () => {
        try {
            const response = await fetch('/api/tab/?format=json', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            
            let data = await response.json();
            console.log('Success:', data);
    
            if (publicTab) {
                const type = determineSearchType(publicTab.tab.searchType, publicTab.tab.searchDepth);
                data.push({
                    ...publicTab,
                    isPublic: true,
                    uuid: publicTab.tab.uuid,
                    name: `Shared: ${publicTab.tab.name}`,
                    query: publicTab.tab.query,
                    results: publicTab.search_results.results,
                    isClientSideOnly: true,
                    mode: "results",
                    type: type,
                });
                setCurrentTabUuid(publicTab.tab.uuid);
            }
    
            setTabs(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    useEffect(() => {
        getUserTabs();
    }, []);
    

    useEffect(() => {
        if (currentTabUuid) {
            fetchTabAndResults(currentTabUuid);
        }
    }, [currentTabUuid]);

    const createNewTab = () => {
        if (!newTabInProgress) {
            const newTab = {
                uuid: tabs.length + 1,
                name: `New Tab`,
                query: "",
                results: [],
                isClientSideOnly: true,
                mode: "query",
            };
            setTabs([newTab, ...tabs]);
            setCurrentTabUuid(newTab.uuid);
            setNewTabInProgress(true);
        }
    };

    const determineSearchType = (searchType, searchDepth) => {
        if (searchType === 'P') {
            return searchDepth === 'T' ? 'People Top' : 'People Depth';
        } else if (searchType === 'C') {
            return searchDepth === 'T' ? 'Company Top' : 'Company Depth';
        }
        return null;
    }

    const fetchTabAndResults = (uuid) => {
        console.log('Fetching tab and results');
        let selectedTab = tabs.find(tab => tab.uuid === uuid);
        if (selectedTab && selectedTab.hasOwnProperty('isClientSideOnly') && selectedTab.isClientSideOnly) {
            console.log('Client-side tab');
            return;
        }        
        if (publicTab && publicTab.tab.uuid === uuid) {
            const type = determineSearchType(publicTab.tab.searchType, publicTab.tab.searchDepth);
            fetchTabResults(uuid, type, true);
        } else {
            fetch(`/api/tab/${uuid}/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                },
                credentials: 'include',
            })
            .then(response => response.json())
            .then(tabData => {
                const type = determineSearchType(tabData.searchType, tabData.searchDepth);
                // console.log(type);
                fetchTabResults(uuid, type);
            })
            .catch(error => {
                console.error('Error fetching tab:', error);
            });
        }
    }

    const fetchTabResults = (uuid, type, public_tab=false) => {
        if (public_tab) {
            // already fetched the results on initial load so just refer to tabarray for results
            let public_results = publicTab.search_results.results;
            setTabs(
                tabs.map((tab) =>
                    tab.uuid === uuid ? { ...tab, results: public_results, type: type, mode: "results" } : tab
                )
            );
            return;
        }
        
        fetch(`/api/tab-result/${uuid}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            credentials: 'include',
        })
        .then(response => response.json())
        .then(data => {
            console.log('Fetched results:', data);
            setTabs(
                tabs.map((tab) => 
                    tab.uuid === uuid ? { ...tab, results: data, type: type, mode: "results" } : tab
                )
            );
        })
        .catch(error => {
            console.error('Error fetching tab results:', error);
        });
    };

    const selectTab = (uuid) => {
        setCurrentTabUuid(uuid);
        fetchTabAndResults(uuid);
    }

    const deleteTab = (uuid) => {
        const tabToDelete = tabs.find((tab) => tab.uuid === uuid);

        // If the tab is a client-side only tab
        if (tabToDelete && tabToDelete.isClientSideOnly) {
            setTabs(tabs.filter((tab) => tab.uuid !== uuid));
            setCurrentTabUuid(null);
            console.log('Client-side tab deleted');

            if (newTabInProgress) {
                setNewTabInProgress(false);
            }

            return; // Exit the function early
        }

        fetch(`/api/delete-tab/${uuid}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            credentials: 'include',
        })
        .then(response => {
            if (response.status === 204) {
                console.log('Success: Tab deleted');
                setTabs(tabs.filter((tab) => tab.uuid !== uuid));
                setCurrentTabUuid(null);
            } else {
                throw new Error('Tab deletion failed');
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };
    

    const shareTab = (uuid) => {
        fetch('/api/make-tab-public/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            credentials: 'include',
            body: JSON.stringify({
                uuid: uuid,
            }),
        })
        .then(response => {
            if (response.status === 200) {
                // console.log('Success: Tab shared');
            } else {
                throw new Error('Tab sharing failed');
            }
        });

        navigator.clipboard.writeText(`${window.location.href}?tab=${uuid}`);
    };
    
    const renameTab = (uuid, newName) => {
        setTabs(
            tabs.map((tab) => (tab.uuid === uuid ? { ...tab, name: newName } : tab))
        );

        fetch(`/api/rename-tab/${uuid}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            credentials: 'include',
            body: JSON.stringify({
                name: newName,
            }),
        })
        .then(response => {
            if (response.status === 200) {
                // console.log('Success: Tab renamed');
            } else {
                throw new Error('Tab rename failed');
            }
        });
    };

    const changeTabMode = (uuid, newMode) => {
        setTabs(
            tabs.map((tab) => (tab.uuid === uuid ? { ...tab, mode: newMode } : tab))
        );
    };

    const updateClientSideTab = (oldUUID, serverData) => {
        // Find the index of the client-side tab using the oldUUID
        const tabIndex = tabs.findIndex(tab => tab.uuid === oldUUID);
        
        // Update the tab in the state if it's found
        if (tabIndex !== -1) {
            const updatedTabs = [...tabs];
            updatedTabs[tabIndex].uuid = serverData.tab.uuid;
            updatedTabs[tabIndex].name = serverData.tab.name;
            updatedTabs[tabIndex].isClientSideOnly = false;
            setTabs(updatedTabs);
            setCurrentTabUuid(serverData.tab.uuid);
        }
    }
    
    const currentTab = tabs.find((tab) => tab.uuid === currentTabUuid);
    
    if (currentTab) {
        console.log(currentTab.mode);
        console.log(currentTab.uuid);
    }
    
    return (
        <div style={{ display: 'flex', overflow: 'hidden' }}>
            {!sidebarOpen && (
                <IconButton 
                    onClick={() => setSidebarOpen(true)} 
                    style={{ 
                        width: '48px', 
                        height: '48px', 
                        position: 'absolute', 
                        top: '10px', 
                        left: '10px',
                        zIndex: 10,
                    }}
                >
                    <MenuIcon />
                </IconButton>
            )}
            <div style={{ minWidth: sidebarOpen ? '250px' : '0px', transition: 'min-width 0.3s' }}>
                <Sidebar isOpen={sidebarOpen} handleDrawerClose={() => setSidebarOpen(false)} createNewTab={createNewTab}>
                    <TabManager
                        tabs={tabs}
                        currentTabUuid={currentTabUuid}
                        selectTab={selectTab}
                        deleteTab={deleteTab}
                        shareTab={shareTab}
                        renameTab={renameTab}
                    />
                </Sidebar>
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', marginLeft: '20px', }}>
                <div style={{ flex: 1, display: 'grid', placeItems: 'center' }}>
                    {currentTab && currentTab.mode === "query" && (
                        <TabQuery
                            uuid={currentTab.uuid}
                            query={currentTab.query}
                            updateClientSideTab={updateClientSideTab}
                            submitTab={() => setNewTabInProgress(false)}
                            setQuery={(newQuery) =>
                                setTabs(
                                tabs.map((tab) =>
                                    tab.uuid === currentTabUuid ? { ...tab, query: newQuery } : tab
                                )
                                )
                            }
                            search={() =>
                                setTabs(
                                    tabs.map((tab) =>
                                        tab.uuid === currentTabUuid ? { ...tab, mode: "results" } : tab
                                    )
                                )
                            }
                            updateUserTabs={getUserTabs}
                            selectTab={selectTab}
                            changeTabMode={changeTabMode}
                        />
                    )}
                </div>
            {currentTab && currentTab.mode === "results" && (
                <TabResults
                    uuid={currentTab.uuid}
                    results={currentTab.results}
                    type={currentTab.type}
                    selectTab={selectTab}
                    updateUserTabs={getUserTabs}
                />
            )}
            </div>
        </div>
    );
}
    
export default HomePage;