import React,  { Component } from 'react';
import { Drawer, IconButton, Divider, Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { ThemeProvider } from '@mui/material/styles';
import { appTheme } from './App';

const Sidebar = ({ children, isOpen, handleDrawerClose, createNewTab }) => (
    <ThemeProvider theme={appTheme}>
        <Drawer variant="persistent" anchor="left" open={isOpen}>
            <div style={{ 'padding': '10px'}}>
                <Button variant="contained" color='primary' onClick={createNewTab}>+ New Query</Button>
                <IconButton style={{ 'marginLeft': '10px'}} onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            {children}
        </Drawer>
    </ThemeProvider>
);

export default Sidebar;