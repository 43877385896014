import React from 'react';
import { ToggleButton, ToggleButtonGroup, Box } from '@mui/material';

const BinaryToggle = ({ toggleState, setToggleState }) => {
    const handleSearchType = (event, newSearchType) => {
        setToggleState(newSearchType);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& > *': {
                m: 1,
                },
            }}
        >
            <ToggleButtonGroup
                color="primary"
                value={toggleState}
                exclusive
                onChange={handleSearchType}
            >
                <ToggleButton value="people">People</ToggleButton>
                <ToggleButton value="company">Company</ToggleButton>
            </ToggleButtonGroup>
        </Box>
    );
}

export default BinaryToggle;