import React,  { useState } from 'react';
import SearchForm from './SearchForm';
import SearchResults from './SearchResults';
import { getCookie } from './Functions';

const TabQuery = ({ uuid, query, setQuery, submitTab, updateClientSideTab, changeTabMode, updateUserTabs, selectTab }) => {
    const [isSearchComplete, setIsSearchComplete] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [resultType, setResultType] = useState(null);

    let currentUuid = uuid;

    const search = (searchQuery) => {
        let type = null;

        if (searchQuery.searchType === 'P') {
            if (searchQuery.searchDepth === 'T') {
                type = 'People Top';
            } else if (searchQuery.searchDepth === 'D') {
                type = 'People Depth';
            }
        } else if (searchQuery.searchType === 'C') {
            if (searchQuery.searchDepth === 'T') {
                type = 'Company Top';
            } else if (searchQuery.searchDepth === 'D') {
                type = 'Company Depth';
            }
        }

        return fetch('/api/create-tab/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            credentials: 'include',
            body: JSON.stringify(searchQuery),
        })
        .then(response => response.json())
        .then(data => {
            setSearchResults(data);
            setIsSearchComplete(true);
            setResultType(type);

            updateClientSideTab(currentUuid, data);

            let uuid  = data.tab.uuid;
            changeTabMode(uuid, 'results');
            return data;
        });
    };
    
    return (
        <div>
            {isSearchComplete ? 
                <SearchResults results={searchResults} type={resultType} updateUserTabs={updateUserTabs} selectTab={selectTab} /> :
                <SearchForm query={query} setQuery={setQuery} search={search} submitTab={submitTab} />
            }
        </div>
    );
};

export default TabQuery;